'use client'
import useSWR from 'swr';
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react';
import React from 'react';
import { API_BASE_URL } from '../common';
import { urlFetcher } from '@/lib/api';
import Link from 'next/link';
import { useUserDetails } from '@/context/UserDetailsContext';

function LoginForm() {
  enum LoginState {
    // On page load, no session established
    NotLoggedIn,

    // Sent credentials to backend, waiting for response
    // validating those credentials
    SendingCredentials,

    // Credentials validated, waiting for session to be created
    // on the backend and for auth requests to succeed
    WaitingForSession,

    // Backend is returning that the user cookie is valid
    SessionEstablished,

    // We've requested a redirect
    Redirecting,
  }

  const auth_check_url = API_BASE_URL + '/auth/check';

  const router = useRouter();
  const [loginState, setLoginState] = useState(LoginState.NotLoggedIn);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');

  const { reloadUserDetailsAndWait } = useUserDetails();

  // Poll for session validation
  const [pollInterval, setPollInterval] = useState(200);  // Start value
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (loginState === LoginState.WaitingForSession) {
      intervalId = setInterval(async () => {
        try {
          const res = await fetch(auth_check_url, { credentials: 'include' });
          if (res.ok && (await res.json()).valid) {
            setLoginState(LoginState.SessionEstablished);
          } else {
            setPollInterval((prev) => Math.min(pollInterval * 1.6, 5000));
          }
        } catch (error) {
          setPollInterval((prev) => Math.min(pollInterval * 1.6, 5000));
        }
      }, pollInterval);  // Every this many ms
    }

    // Cleanup
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [loginState, auth_check_url, LoginState, pollInterval]);

  // Redirect to home page after session is established
  useEffect(() => {
    if (loginState == LoginState.SessionEstablished) {
      setLoginState(LoginState.Redirecting);
      const loadAndRedirect = async () => {
        await reloadUserDetailsAndWait();
        router.push('/');
        router.refresh();
      };
      loadAndRedirect();
    }
  }, [loginState, router, reloadUserDetailsAndWait, LoginState]);


  async function handleLoginSubmit(event: React.FormEvent<HTMLFormElement>) {

    event.preventDefault();
    setLoginState(LoginState.SendingCredentials);
    setLoginErrorMsg('');

    const formData = new FormData(event.currentTarget);

    try {

      const res = await fetch(API_BASE_URL + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          username: formData.get('username'),
          password: formData.get('password'),
        }),
      });

      if (res.ok) {
        // Do nothing here; a useEffect will redirect us to the home page after
        // the backend is able to validate the cookie.
        setLoginState(LoginState.WaitingForSession);
      } else {
        console.log('auth request fail', res);
        const errorData = await res.json();
        setLoginErrorMsg(errorData.message || 'Login failed. Please try again.');
        setLoginState(LoginState.NotLoggedIn);
      }
    } catch (error) {
      console.log('auth request error', error);
      setLoginErrorMsg('An error occurred. Please try again.');
      setLoginState(LoginState.NotLoggedIn);
    } finally {
      // setIsLoggingIn(false);
    }
  }

  const alreadyLoggedIn = false;  // TODO: check if already logged in

  return (
    alreadyLoggedIn
    ?
    <>
      <h2>You are already logged in!</h2>
      <p><Link href="/">Go to home page</Link></p>

      {/* todo: logout button */}
    </>
    :
    <form onSubmit={handleLoginSubmit}>
      <label htmlFor="username">Username:</label><br/>
      <input type="text" id="username" name="username" required /><br/>
      <label htmlFor="password">Password:</label><br/>
      <input type="password" id="password" name="password" required /><br/>
      <button type="submit" disabled={loginState !== LoginState.NotLoggedIn}>
        {/* {loginState ? 'Logging in...' : 'Submit'} */}
        Submit
      </button>
      <p>{
        loginState === LoginState.NotLoggedIn ? null :
        loginState === LoginState.SendingCredentials ? 'Logging in...' :
        loginState === LoginState.WaitingForSession ? 'Redirecting...' :

        // Shouldn't get here
        loginState === LoginState.SessionEstablished ?
          'You are already logged in!' :

        loginState === LoginState.Redirecting ? 'Redirecting...' :

        'unknown login state'
      }</p>
      {loginErrorMsg && <p style={{ color: 'red' }}>{loginErrorMsg}</p>}
    </form>
  )
}

export default function Home() {
  return (
    <main>
      <div className="flex-container">
        <div className="flex-item content">
          <h1>Login</h1>
          <LoginForm />
        </div>
      </div>
    </main>
  )
}
